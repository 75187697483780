import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import '../styles/common.sass'
import '../styles/reset.sass'
import Airdrop from '../sections/airdrop/Airdrop'

const AirdropPage = () => {
  return (
    <Layout>
      <Seo title="Epic airdrop" />
      <div className="section">
        <Airdrop />
      </div>
    </Layout>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */

export const Head = () => <Seo title="Epic airdrop" />

export default AirdropPage
