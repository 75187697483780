import React, { useState, useRef, useLayoutEffect, useEffect } from 'react'
import './airdrop.sass'
import { StaticImage } from 'gatsby-plugin-image'

const shuffleIcon = <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="15.2918" cy="15.2918" r="15.1664" fill="#FFE600" stroke="black" strokeWidth="0.250685"/>
<circle cx="15.2918" cy="15.2918" r="13.6623" fill="#F6A12E" stroke="black" strokeWidth="0.250685"/>
<path d="M25 14.9043L18.707 21L17.2038 19.1686L21.3822 15.0137L17 10.9408L18.6306 9L25 14.9043Z" fill="#260900"/>
<path d="M6 15.0957L12.293 9L13.7962 10.8314L9.61784 14.9863L14 19.0592L12.3694 21L6 15.0957Z" fill="#260900"/>
</svg>

const arrowIcon = <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="15.2918" cy="15.2918" r="15.1664" fill="#FFE600" stroke="black" strokeWidth="0.250685"/>
<circle cx="15.2918" cy="15.2918" r="13.6623" fill="#F6A12E" stroke="black" strokeWidth="0.250685"/>
<path d="M20.7856 15.012L13.1076 21.944L11.2736 19.8613L16.3715 15.1364L11.0249 10.5047L13.0143 8.29767L20.7856 15.012Z" fill="#260900"/>
</svg>

const Airdrop = () => {
  const widthRef = useRef(null)
  const [width, setWidth] = useState(0)
  const [playAnimation, setPlayAnimation] = useState(false)

  useEffect(() => {
    const onPageLoad = () => {
      setPlayAnimation(true)
    }
    if (document.readyState === 'complete') {
      onPageLoad()
    } else {
      window.addEventListener('load', onPageLoad)
      return () => window.removeEventListener('load', onPageLoad)
    }
  }, [])

  useLayoutEffect(() => {
    setWidth(widthRef.current.offsetWidth)
  })
  return (
  <div id="airdrop" ref={widthRef} className="airdropSection">
    <div className="airdropInnerWrap">
      <div className="airdropRow">
        <h2>Epic Airdrop</h2>
        <div className="airdropBlocksContainer">
          <h3>Join the $GODLENs community to start completing quests and claiming rewards.</h3>
          <p>GODLENFISH can be found by doing various easy tasks! (difficulty Degenz, reward LOW-HIGH)</p>
          <div className='linksRow'>
            {/* <a target='_blank' href='https://app.questn.com/quest/890178678072676807' rel="noreferrer">QUESTN</a>
            <a target='_blank' href='https://rewards.taskon.xyz/campaign/detail/76488667' rel="noreferrer">TASKON</a>
            <a target='_blank' href='https://airlyft.one/godlenfish/join-godlenfish' rel="noreferrer">AIRLYFT</a> */}
            <a target='_blank' href='https://zealy.io/cw/godlenfish/questboard' rel="noreferrer">ZEALY</a>
            {/* <a target='_blank' href='https://www.intract.io/quest/660ebe160f5e5a9e8238a78d' rel="noreferrer">INTRACT</a> */}
          </div>
        </div>
        <div className="airdropBlocksContainer">
          <h3>GODLENFISH for everyone and no one!</h3>
          <h3>Difficulty EXTRA HARD - Task CTRL +C and CRTL + V - Reward Valuable</h3>
          <p>Just check 24/7  our twitter, look for the post and enter your SOLANA wallet address where its asked for</p>
          <div className='linksRow'>
            <a target="_blank" href='https://twitter.com/godlen_memecoin' rel="noreferrer">Godlenfish Twitter</a>
          </div>
        </div>
      </div>
    </div>
    <div className='airdropBgOverlay'></div>
  </div>
  )
}

export default Airdrop
